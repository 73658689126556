import { LocationResponse } from "#/types";
import ApiService from "../apiService";

export default class LocationService extends ApiService {
  constructor() {
    super("/locations");
  }

  getAddress(zipcode: string, token: string): Promise<LocationResponse> {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.get(`/location/${zipcode}`, headers);
  }
}
