import { useEffect, useState } from "react";
import { Loader, Paragraph, Spacing, Checkbox } from "@sicredi/react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "#/Context";
import PlanService from "#/api/server/planService";
import { getSearchParams } from "#/utils/getSearchParams";
import ProposalService from "#/api/server/proposalService";
import Layout from "#/components/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import DependentService from "#/api/server/dependentService";
import { Genders } from "#/utils/mockedData/data";
import {
  ComplementaryData as ComplementaryDataType,
  ComplementaryDataUpdateRequestError,
  Dependent as DependentType,
} from "#/types";
import BasicForm from "./components/BasicForm";
import AddressForm from "./components/AddressForm";
import "./styles.scss";
import { PAGE } from "#/config/constants";
import ConfirmModal from "#/components/ConfirmModal";
import Footer from "#/components/Footer";
import { useIsAuthenticated } from "#/hooks/useIsAuthenticated";
import { User } from "oidc-client";

type State = {
  loading: boolean;
  showModalInfo: boolean;
};

const InitialState = {
  loading: false,
  showModalInfo: true,
};

const ERROR_DEFAULT = "Preencha esse campo para continuar";
const ERROR_EMAIL_DEFAULT = "Informe um e-mail válido";
const ERROR_PHONE_DEFAULT = "Informe um telefone válido";

export const verifyDependentsData = async (
  contextDependentList: DependentType[],
  token: User["access_token"]
) => {
  const dependentService = new DependentService();

  const dependentsSavedInDatabase = await dependentService.getDependent(token);

  if (!contextDependentList.length && !!dependentsSavedInDatabase.length) {
    dependentsSavedInDatabase.forEach((dependent) => {
      if (!dependent.identify) return;
      dependentService.deleteDependent(dependent.identify, token);
    });
  }
};

export const ComplementaryData = () => {
  const [apiErrors, setApiErrors] =
    useState<ComplementaryDataUpdateRequestError>({});
  const proposalService = new ProposalService();
  const { user } = useIsAuthenticated();

  const {
    state: { dependents, complementaryData, plan },
    dispatch,
    ActionTypes,
  } = GlobalContext();

  const planService = new PlanService();
  let history = useHistory();

  const [{ loading }, setState] = useState<State>(InitialState);

  const [confirmModalDependents, setConfirmModalDependents] =
    useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(ERROR_DEFAULT),
    suid: Yup.string().required(ERROR_DEFAULT),
    gender: Yup.string().required(ERROR_DEFAULT),
    motherName: Yup.string().required(ERROR_DEFAULT),
    civilStatus: Yup.string().required(ERROR_DEFAULT),
    birthDate: Yup.string().required(ERROR_DEFAULT),
    phone: Yup.string()
      .min(14, ERROR_PHONE_DEFAULT)
      .max(15, ERROR_PHONE_DEFAULT)
      .required(ERROR_DEFAULT),
    email: Yup.string().email(ERROR_EMAIL_DEFAULT).required(ERROR_DEFAULT),
    address: Yup.object().shape({
      postalCode: Yup.string().required(ERROR_DEFAULT),
      street: Yup.string().required(ERROR_DEFAULT),
      number: Yup.string().required(ERROR_DEFAULT),
      streetType: Yup.string().nullable(),
      neighborhood: Yup.string().required(ERROR_DEFAULT),
      state: Yup.string().required(ERROR_DEFAULT),
      city: Yup.string().required(ERROR_DEFAULT),
      sbn: Yup.string(),
    }),
    notDependent: Yup.boolean(),
  });

  const onSubmit = async () => {
    setState((oldState) => ({ ...oldState, loading: true }));
    if (values.notDependent) {
      setConfirmModalDependents(true);
      setState((oldState) => ({ ...oldState, loading: false }));
      return;
    }
    doSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    setFieldValue,
    isValid,
    submitCount,
    touched,
  } = useFormik<ComplementaryDataType & { notDependent: boolean }>({
    onSubmit,
    initialValues: complementaryData,
    validationSchema,
  });

  async function doSubmit() {
    try {
      setState((oldState) => ({ ...oldState, loading: true }));
      const dependentService = new DependentService();
      const dependentsSavedInDatabase = await dependentService.getDependent(
        user?.access_token ?? ""
      );
      setApiErrors({});
      setConfirmModalDependents(false);
      const [stateCode, phoneNumber] = values.phone
        .split(" ")
        .map((digits) => digits.replace(/\D/g, ""));

      const proposal = await proposalService.updateProposal(
        user ? user.access_token : "",
        {
          address: {
            ...values.address,
            postalCode: values.address.postalCode.replace(/\D/g, ""),
          },
          phone: phoneNumber,
          stateCode: stateCode,
          email: values.email.toLowerCase(),
          motherName: values.motherName,
        }
      );

      dispatch({
        type: ActionTypes.SET_COMPLEMENTARY_DATA,
        payload: {
          ...complementaryData,
          ...values,
          proposalDate: proposal.proposalDate,
          email: values.email.toLowerCase(),
          hasDependent: !!!dependents.length,
        },
      });

      if (values.notDependent) {
        history.push(PAGE.SIMULATION.PAYMENT());
      } else {
        if (dependentsSavedInDatabase.length <= 0) {
          history.push(PAGE.SIMULATION.ADD_DEPENDENTS());
          return;
        }

        history.push(PAGE.SIMULATION.DEPENDENTS());
      }
    } catch (error: any) {
      let errorMessage =
        "No momento não foi possível continuar a sua proposta, tente mais tarde";

      if (error.response && error.response.data.errors) {
        errorMessage = error.response.data.errors[0];
      }

      history.push({
        pathname: PAGE.SIMULATION.IMPLANTATION_ERROR(),
        state: {
          errorMessage,
        },
      });
    } finally {
      setState((oldState) => ({ ...oldState, loading: false }));
    }
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) =>
        dispatch({
          type: ActionTypes.SET_GEOLOCATION,
          payload: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (!!!getSearchParams("plano") && !!!plan) {
      history.push(PAGE.SIMULATION.PLANS());
      return;
    }

    if (user) {
      if (!!!complementaryData.name) {
        setUserData();
      }
      verifyDependentsData(dependents, user?.access_token ?? "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function setUserData() {
    try {
      setState((oldState) => ({ ...oldState, loading: true }));

      const dependentService = new DependentService();

      const planName = await getPlanName();

      const responsePlanSimulation = await proposalService.addProposal(
        user ? user.access_token : "",
        planName
      );

      setFieldValue("name", responsePlanSimulation.name);
      setFieldValue(
        "gender",
        Genders.find(
          (gender) =>
            gender.label.toLowerCase() ===
            responsePlanSimulation.gender.toLowerCase()
        )?.value
      );
      setFieldValue("suid", responsePlanSimulation.suid);
      setFieldValue("civilStatus", responsePlanSimulation.civilStatus);
      setFieldValue("birthDate", responsePlanSimulation.birthDate);

      const dependentsList: DependentType[] =
        await dependentService.getDependent(user ? user.access_token : "");
      dispatch({
        type: ActionTypes.SET_NATIONALITY,
        payload: {
          ...complementaryData,
          nationality: responsePlanSimulation.nationality,
        },
      });
      dispatch({
        type: ActionTypes.SET_DEPENDENTS,
        payload: dependentsList,
      });

      getLocation();
    } catch (error: any) {
      if (!error.response || error.response.data.errors) {
        const errorMessage =
          error.response.data.errors[0] ??
          "No momento não foi possível continuar a sua proposta, tente mais tarde";

        history.push({
          pathname: PAGE.SIMULATION.IMPLANTATION_ERROR(),
          state: {
            errorMessage,
          },
        });

        return;
      }
    } finally {
      setState((oldState) => ({ ...oldState, loading: false }));
    }
  }

  async function getPlanName(): Promise<string> {
    try {
      if (!!plan) {
        return plan.name;
      }
      const response = await planService.getPlanInfo(getSearchParams("plano"));
      dispatch({
        type: ActionTypes.SET_PLAN,
        payload: { ...response },
      });
      return response.name;
    } catch (error) {
      throw error;
    }
  }

  return user ? (
    <form noValidate onSubmit={handleSubmit}>
      <Layout
        footer={
          <Footer
            nextButtonType="submit"
            previousButtonAction={() => history.push(PAGE.SIMULATION.DETAILS())}
          />
        }
        currentStep={2}
        title="Dados Complementares"
      >
        <Loader show={loading} fullScreen />
        <ConfirmModal
          title="Tem certeza que não deseja adicionar dependentes?"
          text="Após a contratação do plano, caso mude de ideia, você pode adicionar dependentes."
          onConfirm={() => doSubmit()}
          onCancel={() => setConfirmModalDependents(false)}
          onClose={() => setConfirmModalDependents(false)}
          show={confirmModalDependents}
        />

        <Spacing appearance="small" />
        <Paragraph size="medium" weight="normal">
          Essas informações são essenciais para que sua proposta fique mais
          completa.
        </Paragraph>
        <Spacing appearance="x-large" />

        <BasicForm
          apiErrors={apiErrors}
          errors={errors}
          values={values}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <AddressForm
          apiErrors={apiErrors}
          loadingEvent={(isLoading: boolean) =>
            setState((oldState) => ({ ...oldState, loading: isLoading }))
          }
          setFieldValue={setFieldValue}
          errors={errors}
          values={values}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />

        <Spacing appearance="medium" />

        <Checkbox
          className={"buttonAddDependent"}
          data-testid={"want-add-dependent-checkbox"}
          label="Não desejo indicar dependente."
          name="notDependent"
          checked={values.notDependent}
          onChange={() => setFieldValue("notDependent", !values.notDependent)}
        />

        {(!isValid || !!Object.keys(apiErrors).length) && submitCount > 0 && (
          <Paragraph className="errorCheck">
            * Verifique se informou todos os campos corretamente
          </Paragraph>
        )}
      </Layout>
    </form>
  ) : (
    <Loader show={true} />
  );
};

export default ComplementaryData;
