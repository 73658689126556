import React from "react";
import {
  Spacing,
  Paragraph,
  Button,
  Modal,
  Layout as DefaultLayout,
} from "@sicredi/react";
import "./styles.scss";

type Props = {
  title: string;
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  confirmText?: string;
  confirmApereance?: "primary" | "danger" | "link";
  cancelText?: string;
  show?: boolean;
};

export const ConfirmModal: React.FC<Props> = ({
  title,
  text,
  onClose,
  onConfirm,
  onCancel,
  confirmText = "Sim",
  cancelText = "Não",
  show = false,
  confirmApereance = "primary",
}) => {
  return (
    <Modal
      id="modalConfirm"
      data-testid="modal-confirm"
      title={title}
      onClose={onClose}
      show={show}
      size="small"
    >
      <Paragraph>{text}</Paragraph>
      <Spacing appearance="medium" />
      <DefaultLayout.Wrapper className="confirm-button-container">
        <Button
          data-testid="modal-cancel-button"
          block
          ghost
          appearance="primary"
          onClick={onCancel}
        >
          {cancelText}
        </Button>
        <Button
          data-testid="modal-confirm-button"
          block
          appearance={confirmApereance}
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </DefaultLayout.Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
