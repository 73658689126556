import { APPLICATION, PAGE } from "#/config/constants";
import axios, { AxiosResponse } from "axios";

const httpClient = axios.create({
  baseURL: APPLICATION.BFF_URL,
});

export function formErrorDecorator(arrayErrors: string[]) {
  let newApiErrors: any = {};

  for (const errorString of arrayErrors) {
    const error = errorString.split(":");
    error[0] = error[0].replace("Valid", "");
    newApiErrors[error[0]] = newApiErrors[error[0]]
      ? `${newApiErrors[error[0]]}<br />${error[1].trim()}`
      : error[1].trim();
  }
  return newApiErrors;
}
async function requestDecorator(
  req: Promise<AxiosResponse<any, any>>
): Promise<any> {
  try {
    const response = await req;
    return response.data;
  } catch (error: any) {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = PAGE.NOT_AUTHENTICATED();
      return;
    }
    throw error;
  }
}

class ApiService {
  private url: string;
  private version: string;

  constructor(url: string, version: string = "v1") {
    this.url = url;
    this.version = version;
  }

  private getUrl(url: string) {
    return `${this.version}${APPLICATION.BFF_BASE_PATH}${this.url}${url}`;
  }

  protected get(url: string, headers: any) {
    return requestDecorator(httpClient.get(this.getUrl(url), headers));
  }

  protected post(url: string, data: any, headers: any) {
    return requestDecorator(httpClient.post(this.getUrl(url), data, headers));
  }

  protected put(url: string, data: any, headers: any) {
    return requestDecorator(httpClient.put(this.getUrl(url), data, headers));
  }

  protected delete(url: string, headers: any) {
    return requestDecorator(httpClient.delete(this.getUrl(url), headers));
  }
}

export default ApiService;
