import {
  Account,
  ComplementaryData,
  Dependent,
  PlanComplete,
  Geolocation,
} from "#/types";
import { Dispatch } from "react";

export interface ContextProps<T, P> {
  state: T;
  dispatch: Dispatch<P>;
}

export enum ActionTypes {
  SET_PLAN = "SET_PLAN",
  SET_NATIONALITY = "SET_NATIONALITY ",
  SET_COMPLEMENTARY_DATA = "SET_COMPLEMENTARY_DATA",
  SET_ACCOUNT = "SET_ACCOUNT",
  SET_DEPENDENTS = "SET_DEPENDENTS",
  ADD_DEPENDENTS = "ADD_DEPENDENTS",
  UPDATE_DEPENDENT = "UPDATE_DEPENDENT",
  DELETE_DEPENDENT = "DELETE_DEPENDENT",
  SET_CONTRACT_NUMBER = "SET_CONTRACT_NUMBER",
  CLEAR_STATE = "CLEAR_STATE",
  SET_GEOLOCATION = "SET_GEOLOCATION",
}

export type GlobalAction =
  | {
      type: ActionTypes.SET_PLAN;
      payload: PlanComplete;
    }
  | {
      type: ActionTypes.SET_NATIONALITY;
      payload: ComplementaryData & { notDependent: boolean };
    }
  | {
      type: ActionTypes.SET_COMPLEMENTARY_DATA;
      payload: ComplementaryData & { notDependent: boolean };
    }
  | {
      type: ActionTypes.SET_ACCOUNT;
      payload: Account;
    }
  | {
      type: ActionTypes.SET_DEPENDENTS;
      payload: Dependent[];
    }
  | {
      type: ActionTypes.ADD_DEPENDENTS;
      payload: Dependent;
    }
  | {
      type: ActionTypes.UPDATE_DEPENDENT;
      payload: Dependent;
    }
  | {
      type: ActionTypes.DELETE_DEPENDENT;
      payload: number;
    }
  | {
      type: ActionTypes.CLEAR_STATE;
      payload: undefined;
    }
  | {
      type: ActionTypes.SET_CONTRACT_NUMBER;
      payload: string;
    }
  | {
      type: ActionTypes.SET_GEOLOCATION;
      payload: Geolocation;
    };
export interface GlobalState {
  plan?: PlanComplete;
  geolocation?: Geolocation;
  account?: Account;
  contractNumber?: string;
  complementaryData: ComplementaryData & { notDependent: boolean };
  dependents: Dependent[];
}

export interface GlobalContextProps
  extends ContextProps<GlobalState, GlobalAction> {}
