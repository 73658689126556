import React, { Fragment, StrictMode } from "react";
import { hot } from "react-hot-loader/root";

import { Routes } from "#/routes/routes";
import AuthProvider from "#/providers/AuthProvider";
import { GlobalContextProvider } from "#/Context";
export const App: React.FC = () => {
  return (
    <Fragment>
      <main>
        <StrictMode>
          <AuthProvider>
            <GlobalContextProvider>
              <Routes />
            </GlobalContextProvider>
          </AuthProvider>
        </StrictMode>
      </main>
    </Fragment>
  );
};
export default hot(App);
