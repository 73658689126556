const CURRENT_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

export const authConfig: any = {
  client_id: window.env.REACT_APP_AUTH_CLIENT_ID,
  authority: `${window.env.REACT_APP_AUTH_URL}/auth/realms/person/.well-known/openid-configuration`,
  redirect_uri: `${CURRENT_URL}/authentication/callback`,
  response_type: "code",
  logout_uri: `${window.env.REACT_APP_AUTH_URL}/oidc/logout`,
  post_logout_redirect_uri: CURRENT_URL,
  scope: "openid profile email",
  silent_redirect_uri: `${CURRENT_URL}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};
