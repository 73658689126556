import {
  AccountRequest,
  ComplementaryData,
  ComplementaryDataUpdateRequest,
  ImplantationResponse,
  Geolocation,
} from "#/types";
import ApiService from "../apiService";

export default class ProposalService extends ApiService {
  constructor() {
    super("/proposal");
  }

  // Adiciona uma nova proposta
  addProposal(token: string, plan: string): Promise<ComplementaryData> {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.post(`/${plan}`, {}, headers);
  }

  // Adiciona uma nova proposta
  updateProposal(
    token: string,
    data: ComplementaryDataUpdateRequest
  ): Promise<ComplementaryData> {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.put(``, data, headers);
  }

  // Adiciona uma nova proposta
  updateBillingProposal(
    token: string,
    data: AccountRequest
  ): Promise<ComplementaryData> {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.put(`/billing/data`, data, headers);
  }

  // Adesão a propostal
  finishProposal(
    token: string,
    data?: Geolocation
  ): Promise<ImplantationResponse> {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.post(`/implantation`, data, headers);
  }
}
