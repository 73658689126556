type Environment = "dev" | "tst" | "uat" | "prd" | "mock";

let hasDevtools = false;

export function getEnv(): Environment {
  if (process.env.NODE_ENV === "development") {
    window.Devtools = require("@sicredi/dev-tools").default;

    /* istanbul ignore else */
    if (!hasDevtools) {
      window.Devtools.init();
      hasDevtools = true;
    }

    return window.Devtools.currentEnvironment().toLowerCase();
  }

  if (process.env.REACT_APP_PRD === "true") return "prd";

  const matches = window.location.href.match(
    new RegExp(/(dev|tst|uat|prd)(-|\.)sicredi.(in|io)/gim)
  );

  if (matches) {
    return matches[0].replace(/(-|\.)sicredi.(in|io)/gim, "") as Environment;
  }

  return (process.env.REACT_APP_DEFAULT_ENV || "dev") as Environment;
}

export function getMockAddress() {
  if (process.env.NODE_ENV === "development") {
    window.Devtools = window.Devtools || require("@sicredi/dev-tools").default;
    return window.Devtools.mockAddress();
  }

  return "";
}
