import { Dependent } from "#/types";
import ApiService from "../apiService";

export default class DependentService extends ApiService {
  constructor() {
    super("/dependent");
  }

  getDependent(token: string): Promise<Dependent[]> {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.get(``, headers);
  }

  addDependent(data: Dependent, token: string): Promise<Dependent> {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.post(``, data, headers);
  }

  editDependent(data: Dependent, token: string): Promise<Dependent> {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.put(`/${data.identify}`, data, headers);
  }

  deleteDependent(identify: number, token: string) {
    const headers = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return this.delete(`/${identify}`, headers);
  }
}
