import {
  Button,
  Icon,
  Input,
  LabelledValue,
  Layout,
  Spacing,
  Title,
  toast,
} from "@sicredi/react";
import { maskCep } from "#/utils/mask";
import LocationService from "#/api/server/locationService";
import { FormikErrors, FormikTouched } from "formik";
import {
  ComplementaryData,
  ComplementaryDataUpdateRequestError,
} from "#/types";
import { useState } from "react";
import { useIsAuthenticated } from "#/hooks/useIsAuthenticated";

export const INVALID_POSTALCODE_MESSAGE = "O cep informado é inválido";

export const AddressForm: React.FC<{
  apiErrors: ComplementaryDataUpdateRequestError;
  values: ComplementaryData;
  loadingEvent: (isLoading: boolean) => void;
  touched: FormikTouched<ComplementaryData>;
  errors: FormikErrors<ComplementaryData>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ComplementaryData>>;
  handleChange: (e: any) => any;
  handleBlur: (e: any) => any;
}> = ({
  loadingEvent,
  values,
  apiErrors,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
}) => {
  const locationService = new LocationService();
  const [isCityZipcode, setIsCityZipcode] = useState(false);
  const [invalidPostalCode, setInvalidPostalCode] = useState<
    string | undefined
  >(undefined);
  const { user } = useIsAuthenticated();

  async function handleCEP(cep: string) {
    try {
      setFieldValue("address.postalCode", maskCep(cep));
      if (cep.replace("-", "").length < 8) return;
      loadingEvent(true);
      setInvalidPostalCode(undefined);

      const response = await locationService.getAddress(
        cep.replace("-", ""),
        user ? user.access_token : ""
      );
      if (!response.cidade && !response.uf) {
        setInvalidPostalCode(INVALID_POSTALCODE_MESSAGE);
        resetCep();
        return;
      }

      setFieldValue("address.city", response.cidade);
      setFieldValue("address.neighborhood", response.bairro ?? "");
      setFieldValue(
        "address.street",
        response.logradouro
          ? `${response.tipoLogradouro} ${response.logradouro}`
          : ""
      );
      setFieldValue("address.state", response.uf);
      setFieldValue("address.streetType", response.tipoLogradouro);
      setIsCityZipcode(!!!response.logradouro);
    } catch (error) {
      toast.error("Erro ao consultar o cep, por favor tente mais tarde.");
    } finally {
      loadingEvent(false);
    }
  }

  function resetCep() {
    setFieldValue("address.city", "");
    setFieldValue("address.neighborhood", "");
    setFieldValue("address.street", "");
    setFieldValue("address.state", "");
    setFieldValue("address.postalCode", "");
    setFieldValue("address.streetType", "");
  }

  function getErrorPostalcode() {
    if (!!invalidPostalCode) return invalidPostalCode;
    return (
      touched.address?.postalCode &&
      (errors.address?.postalCode || apiErrors.address?.postalCode)
    );
  }

  return (
    <>
      <Spacing appearance="x-large" />
      <Title size="large" weight="normal">
        Endereço do titular
      </Title>
      <Spacing appearance="small" />

      {values.address.city ? (
        <>
          <LabelledValue compact>
            <LabelledValue.Item label="CEP" value={values.address.postalCode} />
            <LabelledValue.Item label="Cidade" value={values.address.city} />
            <LabelledValue.Item
              label="Estado/UF"
              value={values.address.state}
            />
          </LabelledValue>
          <Button onClick={resetCep}>
            Alterar CEP <Icon name="chevron-right" />
          </Button>
          <Spacing appearance="xxx-small" />
        </>
      ) : (
        <Input
          name="address.postalCode"
          data-testid="postalCode"
          label="CEP residencial"
          className="inputs-form"
          type="text"
          required
          maxLength={9}
          onChange={(event) => {
            handleCEP(event.target.value);
          }}
          onBlur={handleBlur}
          value={values.address.postalCode}
          errorMessage={getErrorPostalcode()}
        />
      )}

      <Layout.Wrapper>
        <Input
          name="address.street"
          label="Logradouro"
          className="inputs-form"
          type="text"
          readOnly={
            values.address.street &&
            values.address.street.length > 0 &&
            !isCityZipcode
              ? true
              : false
          }
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address.street}
          errorMessage={
            touched.address?.street &&
            (errors.address?.street || apiErrors.address?.street)
          }
        />

        <Input
          name="address.neighborhood"
          className="inputs-form"
          label="Bairro"
          type="text"
          required
          readOnly={
            values.address.neighborhood &&
            values.address.neighborhood.length > 0 &&
            !isCityZipcode
              ? true
              : false
          }
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address.neighborhood}
          errorMessage={
            touched.address?.neighborhood &&
            (errors.address?.neighborhood || apiErrors.address?.neighborhood)
          }
        />
      </Layout.Wrapper>

      <Layout.Wrapper>
        <Input
          name="address.number"
          className="inputs-form"
          label="Número"
          type="text"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address.number}
          errorMessage={
            touched.address?.number &&
            (errors.address?.number || apiErrors.address?.number)
          }
        />

        <Input
          name="address.sbn"
          label="Complemento"
          className="inputs-form"
          type="text"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address.sbn}
          errorMessage={
            touched.address?.sbn &&
            (errors.address?.sbn || apiErrors.address?.sbn)
          }
        />
      </Layout.Wrapper>
    </>
  );
};

export default AddressForm;
