import { PAGE } from "#/config/constants";
import { GlobalContext } from "#/Context";
import { getSearchParams } from "#/utils/getSearchParams";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { User } from "oidc-client";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export function useIsAuthenticated(redirect: boolean = false) {
  const { oidcUser, logout } = useReactOidc();
  const history = useHistory();
  const {
    state: { plan },
  } = GlobalContext();
  const [user, setUser] = useState<User>();

  const logoff = () => {
    sessionStorage.removeItem("@authentication");
    logout();
  };

  useEffect(() => {
    if (
      redirect &&
      (!!!(oidcUser || sessionStorage.getItem("@authentication")) ||
        (!!!getSearchParams("plano") && !!!plan))
    ) {
      history.push(PAGE.SIMULATION.PLANS());
      return;
    }

    if (oidcUser) {
      sessionStorage.setItem("@authentication", JSON.stringify(oidcUser));
    }

    setUser(
      oidcUser ??
        (sessionStorage.getItem("@authentication")
          ? JSON.parse(sessionStorage.getItem("@authentication") ?? "")
          : undefined)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user, logoff };
}

export function useIsAuthenticatedRedirect() {
  const { oidcUser } = useReactOidc();
  const history = useHistory();
  const {
    state: { complementaryData, plan },
  } = GlobalContext();
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    if (
      !!!(oidcUser || sessionStorage.getItem("@authentication")) ||
      (!!!getSearchParams("plano") && !!!plan)
    ) {
      history.push(PAGE.SIMULATION.PLANS());
      return;
    }
    if (!!!complementaryData.name) {
      history.push(PAGE.SIMULATION.COMPLEMENTARY_DATA());
      return;
    }

    if (oidcUser) {
      sessionStorage.setItem("@authentication", JSON.stringify(oidcUser));
    }
    setUser(
      oidcUser ?? JSON.parse(sessionStorage.getItem("@authentication") ?? "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return user;
}
