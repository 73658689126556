import React from "react";
import { GlobalContext } from "#/Context";
import { maskMoney } from "#/utils/mask";
import { Button, Layout, SimulationBox } from "@sicredi/react";
import "./styles.scss";

export type ButtonsFooterProps = {
  nextButtonAction?: () => any;
  nextButtonText?: string;
  nextButtonType?: string;
  previousButtonText?: string;
  previousButtonAction?: () => any;
};

export const Footer: React.FC<ButtonsFooterProps> = ({
  nextButtonAction,
  nextButtonType,
  previousButtonAction,
  nextButtonText = "Avançar",
  previousButtonText = "Voltar",
}) => {
  const {
    state: { plan, dependents },
  } = GlobalContext();

  const getTotalValue = () => {
    const planValue = plan?.value ?? 0;
    const totalValue = planValue * (dependents.length + 1);
    return maskMoney(totalValue);
  };

  return (
    <div className="footerBox">
      <SimulationBox className="details-box">
        <SimulationBox.Item label={"Operadora"}>
          {plan?.company}
        </SimulationBox.Item>
        <SimulationBox.Item label={"Valor mensal"}>
          {getTotalValue()}
        </SimulationBox.Item>
      </SimulationBox>
      <div className="details-box">
        <Layout.Wrapper className="buttonWrapper">
          <Button
            data-testid="back"
            block
            ghost
            appearance="primary"
            onClick={previousButtonAction}
          >
            {previousButtonText}
          </Button>
          <Button
            data-testid="next"
            onClick={nextButtonAction}
            type={nextButtonType ?? "button"}
            appearance="primary"
            block
          >
            {nextButtonText}
          </Button>
        </Layout.Wrapper>
      </div>
    </div>
  );
};

export default Footer;
