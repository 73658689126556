import React, { Fragment } from "react";
import { Meta, Modal } from "@sicredi/react";
import { Optional } from "utility-types";
import {
  ErrorBoundaryPropsWithFallback,
  ErrorBoundary as ReactErrorBoundary,
} from "react-error-boundary";

import { PAGE } from "../../config/constants";

type Props = {
  title?: string;
  onClose?: () => void;
};

export const Fallback: React.FC<Props> = ({
  title = "Erro inesperado",
  onClose = () => (window.location.href = PAGE.ROOT()),
  children = "Por favor, tente novamente mais tarde.",
}) => {
  return (
    <Fragment>
      <Meta title={title} />

      <Modal
        show={true}
        title={title}
        onClose={onClose}
        primaryButton={{
          onClick: onClose,
          children: "Tentar novamente",
          "data-testid": "error-boundary-fallback-dismiss",
        }}
      >
        {children}
      </Modal>
    </Fragment>
  );
};

export const ErrorBoundary: React.FC<
  Optional<ErrorBoundaryPropsWithFallback, "fallback">
> = (props) => {
  return <ReactErrorBoundary fallback={<Fallback />} {...props} />;
};
