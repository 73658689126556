import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { Loader, Toast } from "@sicredi/react";
import "./global.scss";
import App from "../src/pages/App";
import { startApiMockServer } from "../src/api/server";
import { onResponse } from "../src/utils/request";
import { Fallback, ErrorBoundary } from "../src/components/ErrorBoundary";
import { version } from "./version";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { CLARITY_ID } from "./config/constants";
import { clarity } from "react-microsoft-clarity";

const ROOT = document.getElementById("root")!;

function unmountRender(element: React.ReactElement) {
  unmountComponentAtNode(ROOT);
  render(element, ROOT);
}

unmountRender(<Loader show fullScreen />);

onResponse((response) => {
  function handleClose() {
    window.auth.actions.logout();
    window.location.reload();
  }

  if (response?.status === 401) {
    unmountRender(
      <Fallback title="Acesso negado" onClose={handleClose}>
        Você não possui autorização para acessar essa aplicação.
      </Fallback>
    );
  }
});

/* FAKE AUTH - Alterar quando adicionado autenticação plataforma/CAS */
const authentication = {
  async authenticate() {
    return {};
  },
  getJwtIdToken() {
    return { payload: "user" };
  },
  getAccessToken() {
    return "token";
  },
  logout() {
    return {};
  },
};

authentication
  .authenticate()
  .then((auth) => {
    if (
      process.env.NODE_ENV === "development" &&
      !!process.env.REACT_APP_MIRAGE &&
      process.env.REACT_APP_MIRAGE === "true"
    ) {
      startApiMockServer();
    }

    window.appVersion = version;

    window.auth = {
      ...auth,
      user: authentication.getJwtIdToken()!.payload,
      get token() {
        return authentication.getAccessToken();
      },
      actions: {
        logout: authentication.logout,
      },
    };

    unmountRender(
      <ErrorBoundary>
        <Toast />
        <App />
      </ErrorBoundary>
    );
  })
  .catch((err) => {
    if (err?.status && err?.status !== "URL_NOT_VERIFIED") {
      unmountRender(<Fallback />);
    }
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

if (CLARITY_ID) {
  clarity.init(CLARITY_ID);
}
