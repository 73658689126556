import React, { createContext, useReducer, useContext } from "react";
import {
  GlobalState,
  GlobalAction,
  ActionTypes,
  GlobalContextProps,
} from "./models";

export const initialState = {
  plan: undefined,
  account: undefined,
  geolocation: undefined,
  contractNumber: undefined,
  complementaryData: {
    name: "",
    suid: "",
    gender: "",
    motherName: "",
    civilStatus: "",
    birthDate: "",
    proposalDate: "",
    hasDependent: false,
    phone: "",
    email: "",
    nationality: "",
    address: {
      postalCode: "",
      street: "",
      number: "",
      streetType: "",
      neighborhood: "",
      state: "",
      city: "",
    },
    notDependent: false,
  },
  dependents: [],
} as GlobalState;

const Context = createContext({} as GlobalContextProps);

const reducer = (state: GlobalState, action: GlobalAction): GlobalState => {
  switch (action.type) {
    case ActionTypes.SET_PLAN: {
      return { ...state, plan: action.payload };
    }
    case ActionTypes.SET_NATIONALITY: {
      return { ...state, complementaryData: action.payload };
    }
    case ActionTypes.SET_COMPLEMENTARY_DATA: {
      return {
        ...state,
        complementaryData: {
          ...action.payload,
          nationality: state.complementaryData.nationality,
        },
      };
    }
    case ActionTypes.SET_ACCOUNT: {
      return { ...state, account: action.payload };
    }
    case ActionTypes.SET_DEPENDENTS: {
      return { ...state, dependents: action.payload };
    }
    case ActionTypes.ADD_DEPENDENTS: {
      const newDependents = state.dependents;
      newDependents.push(action.payload);
      return { ...state, dependents: newDependents };
    }
    case ActionTypes.UPDATE_DEPENDENT: {
      const newDependents = state.dependents.map((dependent) =>
        dependent.identify === action.payload.identify
          ? action.payload
          : dependent
      );
      return { ...state, dependents: newDependents };
    }
    case ActionTypes.DELETE_DEPENDENT: {
      const newDependents = state.dependents.filter(
        (dependent) => dependent.identify !== action.payload
      );
      return { ...state, dependents: newDependents };
    }
    case ActionTypes.SET_CONTRACT_NUMBER: {
      return { ...state, contractNumber: action.payload };
    }
    case ActionTypes.SET_GEOLOCATION: {
      return { ...state, geolocation: action.payload };
    }
    case ActionTypes.CLEAR_STATE: {
      return { ...initialState };
    }
    default: {
      throw new Error(`Unsupported action type: ${action}`);
    }
  }
};

type InitialState = {
  globalState?: GlobalState;
};

export const GlobalContextProvider: React.FunctionComponent<InitialState> = ({
  children,
  globalState = initialState,
}: any) => {
  const [state, dispatch] = useReducer(reducer, globalState);
  const value = { state, dispatch } as GlobalContextProps;
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function GlobalContext() {
  const { state, dispatch } = useContext(Context);
  return { state, dispatch, ActionTypes };
}
