import { getEnv, getMockAddress } from "#/utils/getEnv";
import sulamerica from "#/assets/logo_sulamericaodonto.png";
import sulamericaAns from "#/assets/ans_sulamerica.png";

export const APPLICATION = {
  ID: process.env.REACT_APP_ID,
  NAME: `@${process.env.REACT_APP_ID.replace("-", "_").toUpperCase()}`,
  EXTERNAL: ["WEB_CRM", "WEB_PUBLIC"].includes(
    process.env.REACT_APP_JOB_TEMPLATE_TYPE
  ),
  get BFF_URL() {
    const env = getEnv();
    if (env === "mock" || !window.env) {
      return getMockAddress();
    }

    return window.env.BFF_URL;
  },
  get BFF_BASE_PATH() {
    const env = getEnv();
    if (env === "mock" || !window.env) {
      return "";
    }
    return window.env.BFF_BASE_PATH;
  },
  get ENV() {
    return window.env.ENV;
  },
};

export const PAGE = {
  ROOT: () => "/",
  ACCREDITED_DENTAL_OFFICES: () => "/rede-credenciada",
  NOT_AUTHENTICATED: () => "/nao-autenticado",
  HOME: (actionType: string) => `/seguros/${actionType}`,
  SIMULATION: {
    ROOT: () => "/simulacao",
    PLANS: () => `${PAGE.SIMULATION.ROOT()}/planos`,
    DETAILS: () => `${PAGE.SIMULATION.ROOT()}/detalhes`,
    PAYMENT: () => `${PAGE.SIMULATION.ROOT()}/pagamento`,
    COMPLEMENTARY_DATA: () => `${PAGE.SIMULATION.ROOT()}/dados-complementares`,
    DEPENDENTS: () => `${PAGE.SIMULATION.ROOT()}/dependentes`,
    ADD_DEPENDENTS: () => `${PAGE.SIMULATION.ROOT()}/adicionar-dependente`,
    SUMMARY: () => `${PAGE.SIMULATION.ROOT()}/resumo`,
    TERMS: () => `${PAGE.SIMULATION.ROOT()}/termos`,
    SUCCESS: () => `${PAGE.SIMULATION.ROOT()}/sucesso`,
    CANCEL: () => `${PAGE.SIMULATION.ROOT()}/cancelado`,
    IMPLANTATION_ERROR: () => `${PAGE.SIMULATION.ROOT()}/erro-contratacao`,
  },
};

export enum COMPANIES {
  SULAMERICA = "SULAMERICA",
}

export const logos = {
  [COMPANIES.SULAMERICA]: sulamerica,
};

export const ansImages = {
  [COMPANIES.SULAMERICA]: sulamericaAns,
};

export const CLARITY_ID = window.env?.CLARITY_ID ?? undefined;

export const MY_PLAN_URL =
  window.env?.ENV !== "prd"
    ? `https://healthinsurance-management-customer-web.${window.env?.ENV}.sicredi.cloud`
    : `https://meus-planos.app.sicredi.com.br`;
