import React from "react";
import {
  Button,
  Icon,
  Layout as SicrediLayout,
  Meta,
  Multistep,
  Title,
} from "@sicredi/react";
import "./styles.scss";
import AnsDetail from "./AnsDetail";
import { GlobalContext } from "#/Context";
import { useIsAuthenticated } from "#/hooks/useIsAuthenticated";

export const Layout: React.FC<{
  title: string;
  currentStep?: number;
  noStep?: boolean;
  showAns?: boolean;
  footerOnlyButtons?: boolean;
  footer?: React.ReactElement;
  additionalLayoutClassName?: string;
}> = ({
  title = "",
  currentStep = 0,
  noStep = false,
  showAns = false,
  footer,
  footerOnlyButtons = false,
  additionalLayoutClassName = "",
  children,
}) => {
  const {
    state: { plan },
  } = GlobalContext();

  const { user, logoff } = useIsAuthenticated(false);

  return (
    <SicrediLayout
      className={`layout ${additionalLayoutClassName} ${
        user && "authenticated"
      } ${footerOnlyButtons ? "footer-only-buttons" : ""}`}
    >
      <Meta title={title} />
      <SicrediLayout.Container className="container">
        <SicrediLayout.Content className="content">
          {user && (
            <div className="navbar">
              <Button data-testid="logoutBtn" onClick={() => logoff()}>
                <Icon name="exit" />
                Sair
              </Button>
            </div>
          )}
          {!noStep && (
            <Multistep
              className="multistep"
              currentStep={currentStep > 5 ? 5 : currentStep}
            >
              <Multistep.Item
                name="plan"
                label="Plano"
                render={() => <Title as="h1">Escolha o plano</Title>}
              />
              <Multistep.Item
                name="detail"
                label="Detalhes"
                render={() => <Title as="h1">Plano odonto</Title>}
              />
              <Multistep.Item
                name="data"
                label="Dados"
                render={() => (
                  <Title as="h1">Precisamos de mais alguns dados</Title>
                )}
              />
              <Multistep.Item
                name="dependents"
                label="Dependentes"
                render={() => <Title as="h1">Dependentes</Title>}
              />
              <Multistep.Item
                name="data"
                label="Pagamento"
                render={() => (
                  <Title as="h1">
                    Informe a conta que deseja para o débito
                  </Title>
                )}
              />
              <Multistep.Item
                name="conclusion"
                label="Concluir"
                render={() => (
                  <Title as="h1">
                    {currentStep > 5
                      ? "Contratação do Plano Odonto"
                      : "Confira os dados da sua contratação"}
                  </Title>
                )}
              />
            </Multistep>
          )}
          {children}
          {showAns && plan && <AnsDetail />}
        </SicrediLayout.Content>
      </SicrediLayout.Container>
      {footer}
    </SicrediLayout>
  );
};

export default Layout;
