import { useIsAuthenticated } from "#/hooks/useIsAuthenticated";
import { Button } from "@sicredi/react";
import styles from "./styles.module.scss";

type Props = {
  title?: string;
  description?: string;
};

export const NotAuthenticated: React.FC<Props> = ({
  title = "Usuário não autenticado",
  description = "Você precisa estar autenticado para acessar essa página.",
}) => {
  const { logoff } = useIsAuthenticated(false);

  function handleClick() {
    window.location.href = "/";
    logoff();
  }

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.errorWrapper}>
        <div className={styles.logoWrapper}></div>
        <h1>{title}</h1>
        <p>{description}</p>
        <Button
          appearance="primary"
          type="submit"
          block={true}
          onClick={handleClick}
        >
          Retornar para a simulação
        </Button>
      </div>
    </div>
  );
};

export default NotAuthenticated;
