import React, { lazy, Suspense } from "react";
import { Loader } from "@sicredi/react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { PAGE } from "../config/constants";

const simulationRoutes = lazy(() => import("./simulationRoutes"));
const LazyNotFound = lazy(() => import("../pages/NotFound"));
const LazyNotAuthenticated = lazy(() => import("../pages/NotAuthenticated"));
const LazyAccreditedDentalOffices = lazy(
  () => import("../pages/AccreditedDentalOffices")
);

export const Routes: React.FC = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Suspense fallback={<Loader show fullScreen />}>
      <Switch>
        <Redirect exact path={PAGE.ROOT()} to={PAGE.SIMULATION.ROOT()} />
        <Route path={PAGE.SIMULATION.ROOT()} component={simulationRoutes} />
        <Route
          path={PAGE.ACCREDITED_DENTAL_OFFICES()}
          component={LazyAccreditedDentalOffices}
        />
        <Route
          path={PAGE.NOT_AUTHENTICATED()}
          component={LazyNotAuthenticated}
        />
        <Route path="*" component={LazyNotFound} />
      </Switch>
    </Suspense>
  </Router>
);
