import React, { useCallback } from "react";
import { authConfig } from "../config/auth";
import { useHistory } from "react-router-dom";
import {
  AuthenticationProvider,
  InMemoryWebStorage,
  oidcLog,
} from "@axa-fr/react-oidc-context";
import NotAuthenticated from "#/pages/NotAuthenticated";
import { Loader } from "@sicredi/react";
import ComplementaryData from "#/pages/ComplementaryData";

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const handleFeedback = useCallback(
    (title: string, description: string) => (
      <NotAuthenticated title={title} description={description} />
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  return (
    <AuthenticationProvider
      configuration={{ ...authConfig }}
      loggerLevel={oidcLog.ERROR}
      // isEnabled={true}
      // customEvents={{}}
      notAuthenticated={() =>
        handleFeedback(
          "Sem autenticação.",
          "Você precisa estar autenticado para acessar essa página."
        )
      }
      notAuthorized={() =>
        handleFeedback(
          "Sem autorização.",
          "Você não possui autorização para acessar essa página."
        )
      }
      sessionLostComponent={() =>
        handleFeedback(
          "Sessão encerrada.",
          "Sua sessão foi perdida, por favor, faça login novamente."
        )
      }
      authenticating={() => <ComplementaryData />}
      callbackComponentOverride={() => <Loader show={true} />}
      UserStore={InMemoryWebStorage}
    >
      {children}
    </AuthenticationProvider>
  );
};

export default AuthProvider;
