import { ComplementaryData } from "#/types";
import heartLogo from "../../assets/logo_heart.svg";
import lockLogo from "../../assets/logo_lock.svg";
import playLogo from "../../assets/logo_play.svg";

// Page - Login
export const DocumentCPF = {
  label: "CPF",
  value: "1",
};

export const DocumentCNPJ = {
  label: "CNPJ",
  value: "2",
};

export const mockDependentData = [
  {
    identify: 30221,
    name: "ALBERTO FERNANDES FERREIRA LACERDA",
    suid: "765.352.920-88",
    gender: "M",
    birthDate: "1988-01-01",
    motherName: "Mãe teste",
    kinshipLevel: "SPOUSE",
  },
];

// Page - Products
export const Plans = [
  {
    name: "Plano Odonto",
    description: "Plano completo com proteções essenciais para o segurado.",
    icon: heartLogo,
    availble: true,
    status: false,
    products: [],
  },
  {
    name: "Plano de Saúde",
    description: "Plano completo + proteção de doenças graves.",
    icon: lockLogo,
    availble: false,
    status: false,
    products: [],
  },
  {
    name: "Telemedicina",
    description: "Personalize as proteções conforme perfil do associado.",
    icon: playLogo,
    availble: false,
    status: false,
    products: [],
  },
];

// Select - Gender
export const GenderMale = {
  label: "Masculino",
  value: "M",
};

export const GenderFemale = {
  label: "Feminino",
  value: "F",
};

export const Genders = [GenderMale, GenderFemale];

// Select - Marital State
export const maritalSingle = {
  label: "Solteiro",
  value: "SOLTEIRO",
};

export const maritalMaried = {
  label: "Casado",
  value: "CASADO",
};

export const maritalDivorced = {
  label: "Divorciado",
  value: "DIVORCIADO",
};

export const maritalWidower = {
  label: "Viúvo",
  value: "VIÚVO",
};

export const Maritals = [
  maritalSingle,
  maritalMaried,
  maritalDivorced,
  maritalWidower,
];

// Select - Kinship
export const kinships = [
  {
    label: "Cônjuge",
    value: "SPOUSE",
  },
  {
    label: "Filho/Filha",
    value: "CHILD",
  },
  {
    label: "Pai/Mãe",
    value: "PARENT",
  },
  {
    label: "Pai/Mãe Divorciados",
    value: "UNMARRIED_PARTNERS",
  },
  {
    label: "Permanentemente Dependente",
    value: "PERMANENTLY_DEPENDENT",
  },
  {
    label: "Outros",
    value: "OTHER",
  },
];

export const kinshipDictionary: Record<string, string> = {
  SPOUSE: "Cônjuge",
  CHILD: "Filho/Filha",
  PARENT: "Pai/Mãe",
  UNMARRIED_PARTNERS: "Pai/Mãe Divorciados",
  PERMANENTLY_DEPENDENT: "Permanentemente Dependente",
  OTHER: "Outros",
};

export const observations =
  "(*) Valores em destaque correspondentes a mensalidade por pessoa. Você poderá utilizar o plano no prazo de 24h após o cadastro e da identificação do pagamento da primeira mensalidade de acordo com os prazos de carência de cada modalidade. Plano mensal contempla carência para emergências, tratamentos, consultas e próteses.";

export const documents = [
  {
    text: "Condições Gerais",
    href: "https://www.sulamerica.com.br/odonto/CG_Totality_INSP.pdf",
  },
  {
    text: "MPS (Manual de Orientação para Contratação de Planos de Saúde)",
    href: "https://www.sulamerica.com.br/odonto/MPS.pdf",
  },
  {
    text: "Tipos de Contratação",
    href: "https://www.sulamerica.com.br/odonto/RN_432.pdf",
  },
];

export const infoPlans = {
  informations: [
    {
      title: "Rede credenciada",
      description:
        "+ de 35 mil opções de atendimento pelo país. Consulte a rede credenciada na sua região.",
      link: {
        text: "Consulte a região",
        isModal: true,
      },
    },
    {
      title: "Carência",
      subInformations: [
        {
          title: "Urgência e Emergência",
          description: "24h",
        },
        {
          title: "Tratamento clínico",
          description:
            "(diagnóstico, radiologia, prevencão em saúde bucal, dentística, endodontia, odontopediatria, periodontia, cirurgia e disfunção têmporo mandibular)<br />90 dias",
        },
        {
          title: "Prótese Rol ANS",
          description: "180 dias",
        },
      ],
      link: {
        description:
          "Para conferir todos os procedimentos cobertos no Rol da ANS (Agência Nacional de Saúde Suplementar), ",
        text: "veja as condições gerais",
        href: "https://www.sulamerica.com.br/odonto/CG_Totality_INSP.pdf",
      },
    },
    {
      title: "Coberturas",
      description:
        "Estão cobertos todos tratamentos previstos no ROL da ANS, dentre eles:",
      items: [
        "Prevenção e Diagnóstico",
        "Emergências 24h",
        "Tratamento de canal",
        "Radiografias",
        "Cirurgias",
        "Próteses",
      ],
      link: {
        description:
          "Para conferir todos os procedimentos cobertos no Rol da ANS (Agência Nacional de Saúde Suplementar), ",
        text: "veja as condições gerais",
        href: "https://www.sulamerica.com.br/odonto/CG_Totality_INSP.pdf",
      },
    },
    {
      title: "Sobre o plano",
      subInformations: [
        {
          title: "Coparticipação",
          description:
            "Sem cooparticipação (em tratamentos cobertos pelo plano)",
        },
        {
          title: "Cancelamento",
          description:
            "O plano tem vigência de 12 meses, cancelamentos antes deste prazo será cobrado multa de 20% do valor das mensalidades remanescentes. A operadora poderá rescindir o contrato em caso de fraude ou por não pagamento de mensalidade a partir de 60 dias consecutivos ou não, nos últimos doze meses de vigência do contrato.",
        },
        {
          title: "Vencimento das mensalidades",
          description:
            "A primeira mensalidade ocorrerá em até 5 dias e a partir da segunda mensalidade, será sempre no dia da contratação do plano.",
        },
        {
          title: "Multa por atraso",
          description: "Será cobrada na próxima mensalidade",
        },
        {
          title: "Dependentes",
          description:
            "Podem ser incluídos familiares e dependentes para desfrutar dos benefícios do plano odontológico.",
        },
      ],
    },
    {
      title: "Documentos",
      listLink: documents,
    },
  ],
};

export const infoTerms = {
  information: [
    {
      title: "CONTRATO DE PRESTAÇÃO DE SERVIÇOS DO PLANO ODONTO",
    },
    {
      title: "Sobre as informações presentes",
      description:
        "Declaro que todas as informações prestadas neste Termo de Adesão para esta contratação são verdadeiras e completas.",
    },
    {
      title: "Sobre documentação do plano:",
      description:
        'Declaro que li e compreendi todo o conteúdo das Condições Gerais do Plano (disponível em: <a href="https://www.sulamerica.com.br/odonto/CG_Totality_INSP.pdf" target="_blank">https://www.sulamerica.com.br/odonto/CG_Totality_INSP.pdf</a>, Guia de Leitura Contratual (disponível em: <a href="https://www.sulamerica.com.br/odonto/RN_432.pdf" target="_blank">https://www.sulamerica.com.br/odonto/RN_432.pdf</a>) e o Manual de Orientação para Contratação de Planos de Saúde (disponível em: <a href="https://www.sulamerica.com.br/odonto/MPS.pdf" target="_blank">https://www.sulamerica.com.br/odonto/MPS.pdf</a>) conforme anexo da RN nº 432/2017.',
    },
    {
      title: "Sobre dependentes:",
      items: [
        "Declaro que o(s) dependente(s) incluído(s) no plano odonto está(ão) de acordo com a elegibilidade descrita na cláusula 10 das Condições Gerais;",
        "Declaro ter ciência acerca do valor a ser acrescido na mensalidade relativa ao valor em reais por pessoa para cada dependente incluído no plano;",
        "Declaro ter ciência que se houver previsão de cumprimento de carência para a utilização do plano odonto, estas deverão ser cumpridas por cada dependente incluído.",
      ],
    },
    {
      title: "Sobre coberturas oferecidas:",
      description:
        "Declaro estar ciente das coberturas oferecidas pelo plano odontológico contratado e que estão de acordo com o Rol de Procedimentos e Eventos em Saúde da ANS vigentes a época do evento.",
    },
    {
      title: "Sobre atualização de rede credenciada:",
      description:
        "Declaro estar ciente de que as movimentações da Rede Credenciada estão disponíveis no site sulamerica.com.br ou na Central de Atendimento da SulAmérica Odonto cujo número do telefone consta no cartão de identificação virtual, o qual tenho acesso por meio do aplicativo SulAmérica Odonto.",
    },
    {
      title: "Autorização de débito automático:",
      description:
        "Autorizo a SUL AMÉRICA ODONTOLÓGICO S/A inscrita no CNPJ n.º 11.973.134/0001-05, a efetuar o débito automático em minha conta corrente e data  acima informada, no valor mensal correspondente a quitação do plano. Estou ciente (i) que caso não tenha saldo suficiente em minha conta para realização do débito automático, meu plano será cancelado por inadimplência após 60 (sessenta) dias, consecutivos ou não, bem como; (ii) que haverá cobrança de multa em relação à mensalidade(s) não quitada(s), caso o cancelamento ocorra em prazo inferior a 12 (doze) meses de vigência do contrato, independente do motivo, nos termos das Condições Gerais.",
    },
  ],
  phones: [
    {
      label: "Central de Atendimento SulAmerica",
      phone: "3003 1159",
    },
  ],
  documents: [
    {
      text: "Condições Gerais",
      href: "https://www.sulamerica.com.br/odonto/CG_Totality_INSP.pdf",
    },
    {
      text: "MPS (Manual de Orientação para Contratação de Planos de Saúde)",
      href: "https://www.sulamerica.com.br/odonto/MPS.pdf",
    },
    {
      text: "Tipos de Contratação",
      href: "https://www.sulamerica.com.br/odonto/RN_432.pdf",
    },
  ],
};

export const mockComplementaryData: ComplementaryData = {
  name: "Nome Teste",
  suid: "04499669090",
  gender: "M",
  motherName: "Mother Name Test",
  civilStatus: "CASADO",
  nationality: "string",
  birthDate: "2022-12-05",
  proposalDate: "2022-12-05",
  address: {
    postalCode: "99999-999",
    streetType: "STREET TYPE TEST",
    street: "Street Teste",
    number: "99",
    sbn: "SBN TEST",
    neighborhood: "neighborhood test",
    state: "state test",
    city: "city test",
  },
  phone: "(61) 98213-2988",
  stateCode: "string",
  email: "emailtest@test.com",
};
