import { Plan, PlanComplete } from "#/types";
import ApiService from "../apiService";

export default class PlanService extends ApiService {
  static getPlanInfo(arg0: string) {
    throw new Error("Method not implemented.");
  }
  constructor() {
    super("/plan");
  }

  // Busca os dados básicos dos planos disponíveis
  getPlans(): Promise<Plan[]> {
    const headers = {
      headers: {
        Accept: "application/json",
      },
    };
    return this.get(``, headers);
  }

  // Busca os dados de um plano específico
  getPlanInfo(planName: string): Promise<PlanComplete> {
    const headers = {
      headers: {
        Accept: "application/json",
      },
    };
    return this.get(`/${planName}`, headers);
  }
}
