import { Icon, Input, Layout, Spacing, Title } from "@sicredi/react";
import { Genders, Maritals } from "#/utils/mockedData/data";
import { maskCpf, maskPhone } from "#/utils/mask";
import {
  ComplementaryData,
  ComplementaryDataUpdateRequestError,
} from "#/types";
import { FormikErrors, FormikTouched } from "formik";
import dayjs from "dayjs";

export const BasicForm: React.FC<{
  apiErrors: ComplementaryDataUpdateRequestError;
  values: ComplementaryData;
  touched: FormikTouched<ComplementaryData>;
  errors: FormikErrors<ComplementaryData>;
  handleChange: (e: any) => any;
  handleBlur: (e: any) => any;
}> = ({ apiErrors, values, touched, errors, handleChange, handleBlur }) => {
  return (
    <>
      <Title size="large" weight="normal">
        Dados do titular
      </Title>
      <Spacing appearance="small" />
      <Layout.Wrapper className="input-wrapper">
        <Input
          name="suid"
          label="CPF do titular"
          type="text"
          maxLength={14}
          required
          className="inputs-form"
          disabled
          onChange={handleChange}
          onBlur={handleBlur}
          value={maskCpf(values.suid)}
        />

        <Input
          name="name"
          label="Nome completo do titular"
          type="text"
          className="inputs-form"
          required
          disabled
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
        />

        <Input
          name="gender"
          label="Gênero"
          required
          className="inputs-form"
          disabled
          value={
            Genders.find((gender) => gender.value === values.gender)?.label
          }
        />

        <Input
          name="birthDate"
          label="Data de nascimento"
          className="inputs-form"
          required
          disabled
          onChange={handleChange}
          onBlur={handleBlur}
          value={
            values.birthDate ? dayjs(values.birthDate).format("DD/MM/YYYY") : ""
          }
          icon={<Icon name="calendar" />}
        />

        <Input
          name="civilStatus"
          label="Estado Civil"
          className="inputs-form"
          required
          disabled
          value={
            Maritals.find((marital) => marital.value === values.civilStatus)
              ?.label
          }
        />

        <Input
          name="motherName"
          label="Nome da mãe (sem abreviações)"
          type="text"
          className="inputs-form"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.motherName}
          errorMessage={
            touched.motherName && (errors.motherName || apiErrors.motherName)
          }
        />

        <Input
          name="phone"
          label="Telefone com DDD"
          maxLength={15}
          type="text"
          className="inputs-form"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={maskPhone(values.phone)}
          errorMessage={
            touched.phone &&
            (errors.phone || apiErrors.stateCode || apiErrors.phone)
          }
        />

        <Input
          name="email"
          label="E-mail"
          type="text"
          className="inputs-form"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email.toLowerCase()}
          errorMessage={touched.email && (errors.email || apiErrors.email)}
        />
      </Layout.Wrapper>
    </>
  );
};

export default BasicForm;
