import { ansImages, logos } from "#/config/constants";
import { GlobalContext } from "#/Context";
import { Paragraph } from "@sicredi/react";
import React from "react";

export const AnsDetail: React.FC = () => {
  const {
    state: { plan },
  } = GlobalContext();
  return (
    <div className="ansFooter">
      <Paragraph>
        TOTALITY INSP, sob o registro no 472248145
        <br />
        Central de Atendimento SulAmérica:{" "}
        <span className="bold">3003 1159</span>
      </Paragraph>
      <div className="logosBox">
        <img
          className="companyLogo"
          src={logos[plan?.company as keyof typeof logos]}
          alt=""
        />
        <img
          className="ansLogo"
          src={ansImages[plan?.company as keyof typeof ansImages]}
          alt=""
        />
      </div>
    </div>
  );
};

export default AnsDetail;
