import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry, { isRetryableError } from "axios-retry";
import mitt, { Handler } from "mitt";
import { APPLICATION } from "../../config/constants";

type Events = {
  request: AxiosRequestConfig;
  response: AxiosResponse;
};

export const emitter = mitt<Events>();

export const request = axios.create({
  baseURL: APPLICATION.BFF_URL,
  timeout: 30000,
});

axiosRetry(request, {
  // retries on network errors with status 5xx
  retries: 3,
  retryCondition: isRetryableError,
});

// Add a request interceptor
request.interceptors.request.use(
  (config) => {
    if (!window.auth?.token) throw Error("Usuário não autenticado.");

    if (config.headers)
      config.headers.Authorization = `Bearer ${window.auth?.token}`;

    emitter.emit("request", config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
request.interceptors.response.use(
  (response) => {
    emitter.emit("response", response);
    return response;
  },
  (error) => {
    emitter.emit("response", error.response);
    return Promise.reject(error);
  }
);

export function onRequest(callback: Handler<AxiosRequestConfig>) {
  emitter.on("request", callback);

  return () => {
    emitter.off("request", callback);
  };
}

export function onResponse(callback: Handler<AxiosResponse>) {
  emitter.on("response", callback);

  return () => {
    emitter.off("response", callback);
  };
}
